import $ from 'jquery';

$(document).ready(function() {
		
	$(window).scroll(function() {
		var sticky = $('header'),
		scroll = $(window).scrollTop();
	
		if (scroll >= 100) { 
			sticky.addClass('fixed'); 
		} else { 
			sticky.removeClass('fixed');
			
		}
	});

	//HAMBURGER
	
	$("#menu-toggle").click(function(e) {
	    e.preventDefault();
	    $("header").toggleClass("toggled");
	    $("#sidebar-wrapper").toggleClass("toggled");
	});
	
	$("#sidebar-wrapper .close").click(function(e) {
	    e.preventDefault();
	    $("header").removeClass("toggled");
		$("#sidebar-wrapper").toggleClass("toggled");
	});

	var dropdownMenu = $('.dropdown-menu');
	dropdownMenu.each(function() {
		var menu = $(this);
		// Check if the operation has already been performed
		if (menu.find('.column_1_container, .column_2_container').length === 0) {
			var column_1_elements = menu.find('.column_1').clone();
			var column_2_elements = menu.find('.column_2').clone();

			menu.find('.column_1, .column_2').remove();

			var column_1_div = $('<div class="column_1_container menu-container"></div>').append(column_1_elements);
			var column_2_div = $('<div class="column_2_container menu-container"></div>').append(column_2_elements);

			menu.append(column_1_div, column_2_div);
		}
	});

	/*OPEN A FIXED SEARCH BOX*/

	$(".search i").click(function(e) {
		e.preventDefault();
		$(".fibosearch").fadeToggle(300);
	});
});

document.addEventListener('DOMContentLoaded', () => {
	const body = document.body;
	const navMenu = document.getElementById('menu-navigation-1');
	let isSwitchingMenu = false;

	// Ajouter la classe lorsqu'un sous-menu s'ouvre
	navMenu.addEventListener('show.bs.dropdown', () => {
		isSwitchingMenu = true;
		body.classList.add('menu-open');
		setTimeout(() => { isSwitchingMenu = false; }, 0);
	});

	// Retirer la classe lorsqu'un sous-menu se ferme
	navMenu.addEventListener('hide.bs.dropdown', () => {
		if (!isSwitchingMenu) {
			body.classList.remove('menu-open');
		}
	});
});



