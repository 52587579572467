import $ from 'jquery';

$(document).ready(function() {

	$(function() {
		$('.matchHeight').matchHeight();
	});

	setTimeout(function(){
		$('.facets_box_top > .row > div').each(function() {
			if ($(this).find('.wpgb-facet').is(':empty')) {
				$(this).hide();
			}
		});
	}, 1000);

	setTimeout(function(){
		$('.facets_box > .row > div').each(function() {
			if ($(this).find('.wpgb-facet').is(':empty')) {
				$(this).hide();
			}
		});
	}, 1000);

	/*
	var maxHeight = 0;
	$(".flex-products .products .woocommerce-loop-product__title").each(function(){
		if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
	});
	$(".flex-products .products .woocommerce-loop-product__title").height(maxHeight);
	 */

    /* SWITCHER
    $('.toggle-btn').click(function() {
        $(this).parent().toggleClass('active').siblings().removeClass('active');
        //$(this).parent().find('.single_switcher__active').toggleClass('active').siblings().removeClass('active');
    });
     */

    /* AOS ANIMATION FUNCTION

    $(function() {
        AOS.init();
    });
     */


});

//const collapseElementList = document.querySelectorAll('.collapse');
