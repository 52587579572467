import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {

	$('.banner-slider').slick({
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1
	});

	$('.flex-columns-slider').slick({
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300,
		adaptiveHeight: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.slider_temoignages_hp').slick({
		arrows: false,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.slider_clients').slick({
		arrows: false,
		infinite: true,
		slidesToShow: 5,
		slidesToScroll: 5,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.product-slider-img').slick({
		arrows: true,
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: $('.product-slider-img-prev'),
		nextArrow: $('.product-slider-img-next')
	});

	var $slider = $('.slider_profils_hp');

	$slider.on('init', function(event, slick) {
		// Masquer la flèche de gauche au début
		$('.slider_profils_hp-prev').hide();
	});

	$slider.on('afterChange', function(event, slick, currentSlide, nextSlide) {
		// Vérifier le premier slide
		if (currentSlide === 0) {
			$('.slider_profils_hp-prev').hide();
		} else {
			$('.slider_profils_hp-prev').show();
		}

		// Vérifier le dernier slide
		if (slick.slideCount === currentSlide + 1) {
			$('.slider_profils_hp-next').hide();
		} else {
			$('.slider_profils_hp-next').show();
		}
	});

	$slider.slick({
		arrows: true,
		prevArrow: $('.slider_profils_hp-prev'),
		nextArrow: $('.slider_profils_hp-next'),
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 4,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.slider_product_demo').slick({
		arrows: true,
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: $('.slider_product_demo-prev'),
		nextArrow: $('.slider_product_demo-next')
	});

	$('.slider_product_review').slick({
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1
	});

	/*
	$("div[class^=slider_column_]").each(function () {
		let id = $(this).attr('class').split("_").at(-1);
		$(".slider_column_" + id).slick({
			arrows: true,
			dots: false,
			infinite: true,
			speed: 300,
			slidesToShow: 4,
			slidesToScroll: 4,
			prevArrow: $('.slider_column_prev_' + id),
			nextArrow: $('.slider_column_next_' + id),
			responsive: [
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				}
			]
		});
	});
	*/


});